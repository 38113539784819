import {DiagnosisCategory} from "./dto/dataTypes";

export const diagnosisCategories: DiagnosisCategory[] = [
  {
    name: 'PREVENTION',
    displayName: 'Prevention',
    translations: {
      en: 'Prevention',
      de: 'Prävention',
      fr: 'Prévention',
      it: 'Prevenzione',
    },
    values: [
      {
        id: 'impfung',
        name: 'Impfung',
        category: 'PREVENTION',
        displayName: 'Impfung',
        showAdditionalInformation: false,
        additionalInformationRequired: false,
        translations: {
          en: 'Vaccination',
          de: 'Impfung',
          fr: 'Vaccination',
          it: 'Vaccinazione',
        }
      },
      {
        id: 'entwurmung',
        name: 'Entwurmung',
        category: 'PREVENTION',
        displayName: 'Entwurmung',
        showAdditionalInformation: false,
        additionalInformationRequired: false,
        translations: {
          en: 'Deworming',
          de: 'Entwurmung',
          fr: 'Traitement vermifuge',
          it: 'Sverminazione',
        }
      },
      {
        id: 'floh_zeckenmittel',
        name: 'Floh-/Zeckenmittel',
        category: 'PREVENTION',
        displayName: 'Floh-/Zeckenmittel',
        showAdditionalInformation: false,
        additionalInformationRequired: false,
        translations: {
          en: 'Flea/tick remedy',
          de: 'Floh-/Zeckenmittel',
          fr: 'Traitement anti-puce/anti-tique',
          it: 'Trattamento antipulci/antizecche',
        }
      },
      {
        id: 'kastration',
        name: 'Kastration',
        category: 'PREVENTION',
        displayName: 'Kastration',
        showAdditionalInformation: false,
        additionalInformationRequired: false,
        translations: {
          en: 'Neutering',
          de: 'Kastration',
          fr: 'Castration',
          it: 'Castrazione',
        }
      },
      {
        id: 'zahnsteinentfernung',
        name: 'Zahnsteinentfernung',
        category: 'PREVENTION',
        displayName: 'Zahnsteinentfernung',
        showAdditionalInformation: false,
        additionalInformationRequired: false,
        translations: {
          en: 'Tartar removal',
          de: 'Zahnsteinentfernung',
          fr: 'Détartrage',
          it: 'Rimozione del tartaro',
        }
      },
      {
        id: 'gesundheitscheck',
        name: 'Gesundheitscheck',
        category: 'PREVENTION',
        displayName: 'Gesundheitscheck',
        showAdditionalInformation: false,
        additionalInformationRequired: false,
        translations: {
          en: 'Annual check-up',
          de: 'Jahreskontrolle',
          fr: 'Contrôle annuel',
          it: 'Controllo annuale',
        }
      }
    ]
  },
  {
    name: 'ILLNESS',
    displayName: 'Illness',
    translations: {
      en: 'Illness',
      de: 'Krankheit',
      fr: 'Maladie',
      it: 'Malattia',
    },
    values: [
      {
        id: "ohrentzuendung",
        name: "Ohrentzündung",
        category: "ILLNESS",
        displayName: "Ohrentzündung",
        showAdditionalInformation: true,
        additionalInformationRequired: false,
        translations: {
          en: "Ear infection",
          de: "Ohrentzündung",
          fr: "Infection de l'oreille",
          it: "Infezione dell'orecchio"
        }
      },
      {
        id: "hauterkrankung",
        name: "Hauterkrankung",
        category: "ILLNESS",
        displayName: "Hauterkrankung",
        showAdditionalInformation: true,
        additionalInformationRequired: false,
        translations: {
          en: "Skin disease",
          de: "Hauterkrankung",
          fr: "Maladie de peau",
          it: "Malattia della pelle"
        }
      },
      {
        id: "zahnerkrankung",
        name: "Zahnerkrankung",
        category: "ILLNESS",
        displayName: "Zahnerkrankung",
        showAdditionalInformation: true,
        additionalInformationRequired: false,
        translations: {
          en: "Dental disease",
          de: "Zahnerkrankung",
          fr: "Maladie dentaire",
          it: "Malattia dentale"
        }
      },
      {
        id: "schnupfen",
        name: "Schnupfen",
        category: "ILLNESS",
        displayName: "Schnupfen",
        showAdditionalInformation: true,
        additionalInformationRequired: false,
        translations: {
          en: "Cold",
          de: "Schnupfen",
          fr: "Rhume",
          it: "Raffreddore comune"
        }
      },
      {
        id: "husten",
        name: "Husten",
        category: "ILLNESS",
        displayName: "Husten",
        showAdditionalInformation: true,
        additionalInformationRequired: false,
        translations: {
          en: "Cough",
          de: "Husten",
          fr: "Toux",
          it: "Tosse"
        }
      },
      {
        id: "durchfall",
        name: "Durchfall",
        category: "ILLNESS",
        displayName: "Durchfall",
        showAdditionalInformation: true,
        additionalInformationRequired: false,
        translations: {
          en: "Diarrhea",
          de: "Durchfall",
          fr: "Diarrhée",
          it: "Diarrea"
        }
      },
      {
        id: "nierenerkrankung",
        name: "Nierenerkrankung",
        category: "ILLNESS",
        displayName: "Nierenerkrankung",
        showAdditionalInformation: true,
        additionalInformationRequired: false,
        translations: {
          en: "Kidney disease",
          de: "Nierenerkrankung",
          fr: "Maladie rénale",
          it: "Malattia renale"
        }
      },
      {
        id: "verstopfung",
        name: "Verstopfung",
        category: "ILLNESS",
        displayName: "Verstopfung",
        showAdditionalInformation: true,
        additionalInformationRequired: false,
        translations: {
          en: "Constipation",
          de: "Verstopfung",
          fr: "Constipation",
          it: "Costipazione"
        }
      },
      {
        id: "blasenentzuendung",
        name: "Blasenentzündung",
        category: "ILLNESS",
        displayName: "Blasenentzündung",
        showAdditionalInformation: true,
        additionalInformationRequired: false,
        translations: {
          en: "Bladder infection",
          de: "Blasenentzündung",
          fr: "Cystite",
          it: "Cistite"
        }
      },
      {
        id: "urinabsatzbeschwerden",
        name: "Urinabsatzbeschwerden",
        category: "ILLNESS",
        displayName: "Urinabsatzbeschwerden",
        showAdditionalInformation: true,
        additionalInformationRequired: false,
        translations: {
          en: "Urinary problems",
          de: "Urinabsatzbeschwerden",
          fr: "Problèmes urinaires",
          it: "Sintomi urinari"
        }
      },
      {
        id: "fieber",
        name: "Fieber unbekannter Ursache",
        category: "ILLNESS",
        displayName: "Fieber unbekannter Ursache",
        showAdditionalInformation: true,
        additionalInformationRequired: false,
        translations: {
          en: "Fever of unknown origin",
          de: "Fieber unbekannter Ursache",
          fr: "Fièvre de cause inconnue",
          it: "Febbre di causa sconosciuta"
        }
      },
      {
        id: "infektionskrankheit",
        name: "Infektionskrankheit",
        category: "ILLNESS",
        displayName: "Infektionskrankheit",
        showAdditionalInformation: true,
        additionalInformationRequired: true,
        translations: {
          en: "Infectious disease",
          de: "Infektionskrankheit",
          fr: "Maladie infectieuse",
          it: "Malattia infettiva"
        }
      },
      {
        id: "appetitlosigkeit",
        name: "Appetitlosigkeit",
        category: "ILLNESS",
        displayName: "Appetitlosigkeit",
        showAdditionalInformation: true,
        additionalInformationRequired: false,
        translations: {
          en: "Loss of appetite",
          de: "Appetitlosigkeit",
          fr: "Perte d'appétit",
          it: "Perdita di appetito"
        }
      },
      {
        id: "magendarmentzuendung",
        name: "Magendarmentzündung",
        category: "ILLNESS",
        displayName: "Magendarmentzündung",
        showAdditionalInformation: true,
        additionalInformationRequired: false,
        translations: {
          en: "Gastroenteritis",
          de: "Magendarmentzündung",
          fr: "Gastro-entérite",
          it: "Gastroenterite"
        }
      },
      {
        id: "herzerkrankung",
        name: "Herzerkrankung",
        category: "ILLNESS",
        displayName: "Herzerkrankung",
        showAdditionalInformation: true,
        additionalInformationRequired: false,
        translations: {
          en: "Heart disease",
          de: "Herzerkrankung",
          fr: "Cardiopathie",
          it: "Malattia cardiaca"
        }
      },
      {
        id: "lungenerkrankung",
        name: "Lungenerkrankung",
        category: "ILLNESS",
        displayName: "Lungenerkrankung",
        showAdditionalInformation: true,
        additionalInformationRequired: false,
        translations: {
          en: "Lung disease",
          de: "Lungenerkrankung",
          fr: "Maladie pulmonaire",
          it: "Malattia polmonare"
        }
      },
      {
        id: "rueckenschmerzen",
        name: "Rückenschmerzen",
        category: "ILLNESS",
        displayName: "Rückenschmerzen",
        showAdditionalInformation: true,
        additionalInformationRequired: false,
        translations: {
          en: "Back pain",
          de: "Rückenschmerzen",
          fr: "Mal au dos",
          it: "Dolore alla schiena"
        }
      },
      {
        id: "lahmheit",
        name: "Lahmheit",
        category: "ILLNESS",
        displayName: "Lahmheit",
        showAdditionalInformation: true,
        additionalInformationRequired: true,
        translations: {
          en: "Lameness",
          de: "Lahmheit",
          fr: "Boiterie",
          it: "Zoppia"
        }
      },
      {
        id: "augenerkrankung",
        name: "Augenerkrankung",
        category: "ILLNESS",
        displayName: "Augenerkrankung",
        showAdditionalInformation: true,
        additionalInformationRequired: true,
        translations: {
          en: "Eye disease",
          de: "Augenerkrankung",
          fr: "Maladie des yeux",
          it: "Malattia degli occhi"
        }
      },
      {
        id: "bauchspeicheldruesenentzuendung",
        name: "Bauchspeicheldrüsenentzündung",
        category: "ILLNESS",
        displayName: "Bauchspeicheldrüsenentzündung",
        showAdditionalInformation: true,
        additionalInformationRequired: false,
        translations: {
          en: "Inflammation of the pancreas",
          de: "Bauchspeicheldrüsenentzündung",
          fr: "Inflammation pancréatique",
          it: "Pancreatite"
        }
      },
      {
        id: "diabetes",
        name: "Diabetes",
        category: "ILLNESS",
        displayName: "Diabetes",
        showAdditionalInformation: true,
        additionalInformationRequired: false,
        translations: {
          en: "Diabetes",
          de: "Diabetes",
          fr: "Diabète",
          it: "Diabete"
        }
      },
      {
        id: "schilddruesenerkrankung",
        name: "Schilddrüsenerkrankung",
        category: "ILLNESS",
        displayName: "Schilddrüsenerkrankung",
        showAdditionalInformation: true,
        additionalInformationRequired: false,
        translations: {
          en: "Thyroid gland disorder",
          de: "Schilddrüsenerkrankung",
          fr: "Maladie thyroïdienne",
          it: "Malattia della tiroide"
        }
      },
      {
        id: "analbeutelverstopfung",
        name: "Analbeutelverstopfung",
        category: "ILLNESS",
        displayName: "Analbeutelverstopfung",
        showAdditionalInformation: true,
        additionalInformationRequired: false,
        translations: {
          en: "Anal sac congestion",
          de: "Analbeutelverstopfung",
          fr: "Congestion du sac anal",
          it: "Blocco della sacca anale"
        }
      },
      {
        id: "laehmung",
        name: "Lähmung",
        category: "ILLNESS",
        displayName: "Lähmung",
        showAdditionalInformation: true,
        additionalInformationRequired: true,
        translations: {
          en: "Paralysis",
          de: "Lähmung",
          fr: "Paralysie",
          it: "Paralisi"
        }
      },
      {
        id: "hormonelle_erkrankung",
        name: "Hormonelle Erkrankung",
        category: "ILLNESS",
        displayName: "Hormonelle Erkrankung",
        showAdditionalInformation: true,
        additionalInformationRequired: false,
        translations: {
          en: "Hormonal disease",
          de: "Hormonelle Erkrankung",
          fr: "Maladie hormonale",
          it: "Malattia ormonale"
        }
      },
      {
        id: "nervenerkrankung",
        name: "Nervenerkrankung",
        category: "ILLNESS",
        displayName: "Nervenerkrankung",
        showAdditionalInformation: true,
        additionalInformationRequired: false,
        translations: {
          en: "Nerve disease",
          de: "Nervenerkrankung",
          fr: "Maladie nerveuse",
          it: "Malattia nervosa"
        }
      },
      {
        id: "bandscheibenvorfall",
        name: "Bandscheibenvorfall",
        category: "ILLNESS",
        displayName: "Bandscheibenvorfall",
        showAdditionalInformation: true,
        additionalInformationRequired: false,
        translations: {
          en: "Disc prolapse",
          de: "Bandscheibenvorfall",
          fr: "Prolapsus discal",
          it: "Ernia del disco"
        }
      },
      {
        id: "allergie",
        name: "Allergie",
        category: "ILLNESS",
        displayName: "Allergie",
        showAdditionalInformation: true,
        additionalInformationRequired: false,
        translations: {
          en: "Allergy",
          de: "Allergie",
          fr: "Allergie",
          it: "Allergia"
        }
      },
      {
        id: "chirurgie",
        name: "Chirurgie",
        category: "ILLNESS",
        displayName: "Chirurgie",
        showAdditionalInformation: true,
        additionalInformationRequired: true,
        translations: {
          en: "Surgery",
          de: "Chirurgie",
          fr: "Chirurgie",
          it: "Chirurgia"
        }
      },
      {
        id: "krebs",
        name: "Krebs",
        category: "ILLNESS",
        displayName: "Krebs",
        showAdditionalInformation: true,
        additionalInformationRequired: false,
        translations: {
          en: "Cancer",
          de: "Krebs",
          fr: "Cancer",
          it: "Cancro"
        }
      },
      {
        id: "sonstige",
        name: "Sonstige",
        category: "ILLNESS",
        displayName: "Sonstige",
        showAdditionalInformation: true,
        additionalInformationRequired: true,
        translations: {
          en: "Other illness",
          de: "Sonstige Krankheit",
          fr: "Autre maladie",
          it: "Altra malattia"
        }
      }
    ]
  },
  {
    name: "ACCIDENT",
    displayName: "Accident",
    translations: {
      en: "Accident",
      de: "Unfall",
      fr: "Accident",
      it: "Incidente"
    },
    "values": [
      {
        id: "abszess",
        name: "Abszess",
        category: "ACCIDENT",
        displayName: "Abszess",
        showAdditionalInformation: true,
        additionalInformationRequired: true,
        translations: {
          en: "Abscess",
          de: "Abszess",
          fr: "Abcès",
          it: "Ascesso"
        }
      },
      {
        id: "hautwunde",
        name: "Hautwunde",
        category: "ACCIDENT",
        displayName: "Hautwunde",
        showAdditionalInformation: true,
        additionalInformationRequired: true,
        translations: {
          en: "Skin wound",
          de: "Hautwunde",
          fr: "Plaie cutanée",
          it: "Ferita cutanea"
        }
      },
      {
        id: "autounfall",
        name: "Autounfall",
        category: "ACCIDENT",
        displayName: "Autounfall",
        showAdditionalInformation: true,
        additionalInformationRequired: false,
        translations: {
          en: "Car crash",
          de: "Autounfall",
          fr: "Accident de voiture",
          it: "Incidente stradale"
        }
      },
      {
        id: "vergiftung",
        name: "Vergiftung",
        category: "ACCIDENT",
        displayName: "Vergiftung",
        showAdditionalInformation: true,
        additionalInformationRequired: false,
        translations: {
          en: "Intoxication",
          de: "Vergiftung",
          fr: "Empoisonnement",
          it: "Avvelenamento"
        }
      },
      {
        id: "fremdkoerper",
        name: "Fremdkörper-Einnahme",
        category: "ACCIDENT",
        displayName: "Fremdkörper-Einnahme",
        showAdditionalInformation: true,
        additionalInformationRequired: false,
        translations: {
          en: "Ingestion of a foreign body",
          de: "Fremdkörper-Einnahme",
          fr: "Ingestion d'un objet étranger",
          it: "Ingestione di un oggetto estraneo"
        }
      },
      {
        id: "knochenbruch",
        name: "Knochenbruch",
        category: "ACCIDENT",
        displayName: "Knochenbruch",
        showAdditionalInformation: true,
        additionalInformationRequired: true,
        translations: {
          en: "Bone fracture",
          de: "Knochenbruch",
          fr: "Fracture osseuse",
          it: "Frattura dell'osso"
        }
      },
      {
        id: "krallenverletzung",
        name: "Krallenverletzung",
        category: "ACCIDENT",
        displayName: "Krallenverletzung",
        showAdditionalInformation: true,
        additionalInformationRequired: true,
        translations: {
          en: "Claw injury",
          de: "Krallenverletzung",
          fr: "Blessure à la griffe",
          it: "Lesione da artiglio"
        }
      },
      {
        id: "kreuzbandriss",
        name: "Kreuzbandriss",
        category: "ACCIDENT",
        displayName: "Kreuzbandriss",
        showAdditionalInformation: true,
        additionalInformationRequired: true,
        translations: {
          en: "Cruciate ligament tear",
          de: "Kreuzbandriss",
          fr: "Déchirure du ligament croisé",
          it: "Rottura del legamento crociato"
        }
      },
      {
        id: "kippfenster",
        name: "Kippfenster",
        category: "ACCIDENT",
        displayName: "Kippfenster",
        showAdditionalInformation: true,
        additionalInformationRequired: false,
        translations: {
          en: "Tilt window",
          de: "Kippfenster",
          fr: "Fenêtre oscillo-battante",
          it: "Finestra a ribalta"
        }
      },
      {
        id: "sturz",
        name: "Sturz",
        category: "ACCIDENT",
        displayName: "Sturz",
        showAdditionalInformation: true,
        additionalInformationRequired: true,
        translations: {
          en: "Fall",
          de: "Sturz",
          fr: "Chute",
          it: "Caduta"
        }
      },
      {
        id: "muskel_sehnenzerrung",
        name: "Muskel-/Sehnenzerrung",
        category: "ACCIDENT",
        displayName: "Muskel-/Sehnenzerrung",
        showAdditionalInformation: true,
        additionalInformationRequired: true,
        translations: {
          en: "Muscle/ligament tear",
          de: "Muskel-/Sehnenzerrung",
          fr: "Blessure musculaire/tendineuse",
          it: "Stiramento muscolare/tendineo"
        }
      },
      {
        id: "augenverletzung",
        name: "Augenverletzung",
        category: "ACCIDENT",
        displayName: "Augenverletzung",
        showAdditionalInformation: true,
        additionalInformationRequired: true,
        translations: {
          en: "Eye injury",
          de: "Augenverletzung",
          fr: "Blessure à l'œil",
          it: "Lesioni agli occhi"
        }
      },
      {
        id: "zahnverletzung",
        name: "Zahnverletzung",
        category: "ACCIDENT",
        displayName: "Zahnverletzung",
        showAdditionalInformation: true,
        additionalInformationRequired: true,
        translations: {
          en: "Tooth injury",
          de: "Zahnverletzung",
          fr: "Blessure aux dents",
          it: "Lesione del dente"
        }
      },
      {
        id: "bissverletzung",
        name: "Bissverletzung",
        category: "ACCIDENT",
        displayName: "Bissverletzung",
        showAdditionalInformation: true,
        additionalInformationRequired: true,
        translations: {
          en: "Bite wound",
          de: "Bissverletzung",
          fr: "Blessure par morsure",
          it: "Lesione da morso"
        }
      },
      {
        id: "blutung",
        name: "Blutung",
        category: "ACCIDENT",
        displayName: "Blutung",
        showAdditionalInformation: true,
        additionalInformationRequired: true,
        translations: {
          en: "Bleeding",
          de: "Blutung",
          fr: "Saignement",
          it: "Sanguinamento"
        }
      },
      {
        id: "allergische_reaktion",
        name: "Allergische Reaktion",
        category: "ACCIDENT",
        displayName: "Allergische Reaktion",
        showAdditionalInformation: true,
        additionalInformationRequired: true,
        translations: {
          en: "Allergic reaction",
          de: "Allergische Reaktion",
          fr: "Réaction allergique",
          it: "Reazione allergica"
        }
      },
      {
        id: "magendrehung",
        name: "Magendrehung",
        category: "ACCIDENT",
        displayName: "Magendrehung",
        showAdditionalInformation: true,
        additionalInformationRequired: false,
        translations: {
          en: "Gastric torsion",
          de: "Magendrehung",
          fr: "Torsion gastrique",
          it: "Torsione gastrica"
        }
      },
      {
        id: "verbrennung",
        name: "Verbrennung",
        category: "ACCIDENT",
        displayName: "Verbrennung",
        showAdditionalInformation: true,
        additionalInformationRequired: true,
        translations: {
          en: "Skin burn",
          de: "Verbrennung",
          fr: "Brûlure",
          it: "Ustione della pelle"
        }
      },
      {
        id: "hitzschlag",
        name: "Hitzschlag",
        category: "ACCIDENT",
        displayName: "Hitzschlag",
        showAdditionalInformation: true,
        additionalInformationRequired: true,
        translations: {
          en: "Heat stroke",
          de: "Hitzschlag",
          fr: "Coup de chaleur",
          it: "Colpo di calore"
        }
      },
      {
        id: "sonstige",
        name: "Sonstige",
        category: "ACCIDENT",
        displayName: "Sonstige",
        showAdditionalInformation: true,
        additionalInformationRequired: true,
        translations: {
          en: "Other accident",
          de: "Sonstiger Unfall",
          fr: "Autre accident",
          it: "Altro incidente"
        }
      }
    ]
  },
  {
    name: "UNKNOWN",
    displayName: "Unbekannt",
    translations: {
      en: "Unknown",
      de: "Unbekannt",
      fr: "Inconnu",
      it: "Sconosciuto"
    },
    values: [
      /*
      {
        id: "blutuntersuchung",
        name: "Blutuntersuchung",
        category: "UNKNOWN",
        displayName: "Blutuntersuchung",
        translations: {
          en: "Blood test",
          de: "Blutuntersuchung",
          fr: "Analyse de sang",
          it: "TRANSLATION_PLACEHOLDER"
        }
      },
      {
        id: "urinuntersuchung",
        name: "Urinuntersuchung",
        category: "UNKNOWN",
        displayName: "Urinuntersuchung",
        translations: {
          en: "Urine test",
          de: "Urinuntersuchung",
          fr: "Analyse d'urine",
          it: "TRANSLATION_PLACEHOLDER"
        }
      },
      {
        id: "roentgenuntersuchung",
        name: "Röntgenuntersuchung",
        category: "UNKNOWN",
        displayName: "Röntgenuntersuchung",
        translations: {
          en: "X-ray examination",
          de: "Röntgenuntersuchung",
          fr: "Examen radiologique",
          it: "TRANSLATION_PLACEHOLDER"
        }
      },
      {
        id: "kotuntersuchung",
        name: "Kotuntersuchung",
        category: "UNKNOWN",
        displayName: "Kotuntersuchung",
        translations: {
          en: "Stool examination",
          de: "Kotuntersuchung",
          fr: "Examen des selles",
          it: "TRANSLATION_PLACEHOLDER"
        }
      },
      {
        id: "ultraschall",
        name: "Ultraschalluntersuchung",
        category: "UNKNOWN",
        displayName: "Ultraschalluntersuchung",
        translations: {
          en: "Ultrasound scan",
          de: "Ultraschalluntersuchung",
          fr: "Échographie",
          it: "TRANSLATION_PLACEHOLDER"
        }
      },
      {
        id: "ct",
        name: "CT/Computeromographie",
        category: "UNKNOWN",
        displayName: "CT/Computeromographie",
        translations: {
          en: "CT-Scan",
          de: "CT/Computeromographie",
          fr: "Tomodensitométrie",
          it: "TRANSLATION_PLACEHOLDER"
        }
      },
      {
        id: "mrt",
        name: "MRI/MRT/Magnetresonanztomographie",
        category: "UNKNOWN",
        displayName: "MRI/MRT/Magnetresonanztomographie",
        translations: {
          en: "MRI/magnetic resonance imaging",
          de: "MRI/MRT/Magnetresonanztomographie",
          fr: "IRM/Imagerie par résonance magnétique",
          it: "TRANSLATION_PLACEHOLDER"
        }
      },
      */
      {
        id: "sonstige",
        name: "Sonstige",
        category: "UNKNOWN",
        displayName: "Sonstige",
        showAdditionalInformation: true,
        additionalInformationRequired: true,
        translations: {
          en: "Other",
          de: "Sonstige",
          fr: "Autres",
          it: "Altro"
        }
      }
    ]
  }
]
